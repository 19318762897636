import {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Box} from '@mui/material';

import routes from '@/constants/routes';
import {
    selectCurrentCallMeetingId,
    selectCurrentCallPatientId,
    selectShowVideoCallWidget,
} from '@/redux/commonCalls/selectors';
import {closeVideoCallWidget, openVideoCallWidget} from '@/redux/commonCalls/slice';
import {VIDEO_PROVIDER_SETTINGS, videoProvider} from '@/services/videoService';

import {employmentSelector} from '../redux/selectors';
import {VideoCallToolbar} from './VideoCallToolbar';

export const VideoCall = () => {
    const dispatch = useDispatch();
    const checkWindowOpenIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const newWindowRef = useRef<Window | null>(null);
    const meetingId = useSelector(selectCurrentCallMeetingId);
    const patientId = useSelector(selectCurrentCallPatientId);
    const showVideoCallWidget = useSelector(selectShowVideoCallWidget);
    const history = useHistory();
    const endCallRef = useRef(null);
    const user = useSelector(employmentSelector);

    useEffect(() => {
        const url = new URL(window.location.href);
        const isOpen = url.searchParams.get('openVideoCallWidget');
        url.searchParams.delete('openVideoCallWidget');
        if (isOpen === '1') {
            dispatch(openVideoCallWidget());
        }
    }, [dispatch]);

    const handleConnect = useCallback(() => {
        if (!('vidyoConnector' in window)) {
            return;
        }

        vidyoConnector?.SetCameraPrivacy?.({
            privacy: false,
        });
        connectToConference(vidyoConnector);
    }, []);

    const handleDisconnect = useCallback(() => {
        if (!('vidyoConnector' in window)) {
            return;
        }

        vidyoConnector?.Disconnect?.();
        vidyoConnector?.SetCameraPrivacy?.({
            privacy: true,
        });
        vidyoConnector?.SetMicrophonePrivacy?.({
            privacy: true,
        });
    }, []);

    const handleEndCall = useCallback(() => {
        localStorage.removeItem('meetingId');
        localStorage.removeItem('user');

        clearTimeout(endCallRef.current);
        newWindowRef.current?.close?.();
        clearInterval(checkWindowOpenIntervalRef.current);

        endCallRef.current = setTimeout(() => {
            handleDisconnect();

            if (meetingId) {
                videoProvider.leaveCall({
                    meetingId,
                    url: VIDEO_PROVIDER_SETTINGS.VIDYO.providerSpecificBaseUrl,
                });
            }

            newWindowRef.current = null;

            dispatch(closeVideoCallWidget());
        }, 1000);
    }, [dispatch, handleDisconnect, meetingId]);

    const handleGoToPatientPage = useCallback(() => {
        if (!patientId) {
            console.error('currentCallPatientId is not defined');
            return;
        }

        history.push(routes.PATIENT_SINGLE_OVERVIEW(patientId.toString()));
    }, [history, patientId]);

    useEffect(() => {
        if (meetingId && !newWindowRef.current && showVideoCallWidget) {
            localStorage.setItem('meetingId', meetingId || '');
            localStorage.setItem(
                'user',
                JSON.stringify({firstName: user?.firstName, lastName: user?.lastName, id: user?.id}),
            );

            newWindowRef.current = window.open(`/video-call`, '_blank', 'popup=yes,height=600,width=800');
            return;
        }
    }, [meetingId, showVideoCallWidget, user?.firstName, user?.id, user?.lastName]);

    useEffect(() => {
        checkWindowOpenIntervalRef.current = setInterval(() => {
            if (!meetingId) {
                handleEndCall();
                clearInterval(checkWindowOpenIntervalRef.current);
                return;
            }

            if (newWindowRef.current?.closed) {
                handleEndCall();
                clearInterval(checkWindowOpenIntervalRef.current);
                return;
            }

            const joinLeaveButton = document.getElementById('joinLeaveButton');

            if ('vidyoConnector' in window) {
                vidyoConnector?.GetState?.().then((state: string) => {
                    if (!joinLeaveButton) return;

                    if (state.match(/connected/i)) {
                        joinLeaveButton.classList.add('callEnd');
                        joinLeaveButton.classList.remove('callStart');
                    }

                    if (state.match(/ready/i)) {
                        joinLeaveButton.classList.add('callStart');
                        joinLeaveButton.classList.remove('callEnd');
                    }
                });
            }
        }, 1000);

        return () => {
            clearInterval(checkWindowOpenIntervalRef.current);
        };
    }, [handleEndCall, meetingId, showVideoCallWidget]);

    if (!meetingId) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gap: 2,
                paddingRight: 2,
            }}
        >
            <VideoCallToolbar
                newWindowRef={newWindowRef}
                onGoToPatientPage={handleGoToPatientPage}
                onEndCall={handleEndCall}
                onConnect={handleConnect}
                meetingId={meetingId}
            />
        </Box>
    );
};
